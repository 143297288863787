<template>
  <div>
    <el-upload
        style="position: absolute;right: 1rem;top: .8rem;"
        ref="uploader"
        :action="action"
        :headers="{ytoken:vuex_token.data}"
        :data="{group_id:uploadGroupId}"
        name="file"
        :show-file-list="false"
        accept="image/jpg,image/jpeg,image/png,image/ico,image/svg,image/bmp,image/gif"
        :on-success="success"
        :on-error="error"
        multiple
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <el-container style="height: 33rem; border: 1px solid #f7f8f9">
      <el-aside width="150px" style="background-color: #ededed">
        <div @click="changeCurTab('all')" :class="cur_tab == 'all' ? 'active' : ''" class="text-ellipsis y-pointer aside-item">全部</div>
        <div @click="changeCurTab(0)" :class="cur_tab == 0 ? 'active' : ''" class="text-ellipsis y-pointer aside-item">未分组</div>
        <template v-for="(item,key) in group">
          <div :key="key" class="flex-def flex-cCenter" style="position:relative;">
            <div style="width: 100%" @click="changeCurTab(item.id)" :class="cur_tab == item.id ? 'active' : ''" class="text-ellipsis y-pointer aside-item">
              {{ item.title }}
            </div>
            <div style="position:absolute;right: .5rem">
              <el-popconfirm @confirm="groupDel(item.id)" title="确定删除这个分组吗？">
                <el-button style="color: red;" type="text" size="mini" icon="el-icon-delete" slot="reference"></el-button>
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-aside>
      <el-main>
        <div class="flex-def flex-warp" style="height: 27rem">
          <div class="flex-def flex-zCenter flex-cCenter" v-for="(item,key) in list" :key="key" style="margin: .5rem;position: relative;width: calc(20% - 1rem);height: 8rem">
            <div class="flex-def flex-zCenter flex-cCenter" style="width: 8rem;position: relative">
              <el-image @click="chooseImg(item)" class="y-pointer" fit="contain" :src="item.attachment | tomedia" style="background-color: #f8f8f8;width: 8rem;height: 8rem;"></el-image>
              <div class="y-pointer flex-def flex-zCenter flex-cCenter" style="color: #FFFFFF;background-color: rgba(0,0,0,.4);font-size: .8rem;line-height: 1.5rem;position: absolute;bottom: 0;width: 8rem">
                <div class="text-ellipsis" style="width: 95%">{{item.filename}}</div>
              </div>
              <div style="position: absolute;right: 0;top: 0;">
                <el-popover :ref="'popover'+item.id" placement="bottom" width="80" trigger="click">
                  <div>
                    <el-popover :ref="'gpopover'+item.id" placement="top" width="280" trigger="click">
                      <div>
                        <el-select v-model="choose_group_id" placeholder="请选择">
                          <el-option label="未分组" :value="0" ></el-option>
                          <el-option
                              v-for="v in group"
                              :key="v.id"
                              :label="v.title"
                              :value="v.id">
                          </el-option>
                        </el-select>
                        <el-button @click="moveGroup(item.id,'popover'+item.id,'gpopover'+item.id)" type="primary" style="margin-left: .5rem">确认</el-button>
                      </div>
                      <div slot="reference" class="y-pointer" style="line-height: 2rem;margin-bottom: .5rem;text-align: center">
                        <i style="color: #4ca2ff;margin-right: .5rem" class="el-icon-s-fold"></i>
                        <span>移动分组</span>
                      </div>
                    </el-popover>
                    <el-popconfirm @confirm="del(item,'popover'+item.id)" title="确定删除吗？">
                      <div slot="reference" class="y-pointer" style="line-height: 2rem;text-align: center">
                        <i style="color: red;margin-right: .5rem" class="el-icon-delete-solid"></i>
                        <span>删除素材</span>
                      </div>
                    </el-popconfirm>
                  </div>
                  <div slot="reference" class="y-pointer flex-def flex-zCenter flex-cCenter" style="border-radius: 2rem;padding: .2rem;background-color: #4ca2ff;color: #FFFFFF">
                    <i class="el-icon-more"></i>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
          <div v-if="list.length === 0" class="flex-def flex-zCenter flex-cCenter" style="width: 100%;font-size: 1rem;color: #ededed;font-weight: 600">
            还没有资源...
          </div>
        </div>
        <div class="flex-def flex-zCenter flex-cCenter" style="height: 3rem">
          <el-pagination :hide-on-single-page="true" style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
    <div class="flex-def">
      <el-popover v-model="gAdd" placement="top" width="300" trigger="click">
        <div>
          <el-input v-model="gform.title">
            <template #prepend>分组名称</template>
            <template #append><el-button @click="submitGform">确定</el-button></template>
          </el-input>
        </div>
        <div slot="reference" style="color: #5D60F6;font-weight: 600" class="text-ellipsis y-pointer aside-item">新建分组</div>
      </el-popover>
    </div>
  </div>
</template>

<script>

import {requestWithUni} from "@/common/resquest";

export default {
  name: "img_body",
  data() {
    return {
      action:"",
      page:1,
      list:[],
      total:0,
      cur_tab:'all',
      gAdd:false,
      itemCon:false,
      gform:{
        title:""
      },
      group:[],
      choose_group_id:0,
    }
  },
  mounted() {
    this.action = requestWithUni(this.axios.defaults.baseURL + this.$u.api.attach.uploadAction);
    this.open();
  },
  computed:{
    uploadGroupId(){
      if(this.cur_tab == "all"){
        return 0;
      }
      return this.cur_tab;
    }
  },
  methods:{
    groupDel(id){
      this.$u.api.attach.group.del({id}).then(()=>{
        this.changeCurTab(0)
        this.loadGroup();
      })
    },
    submitGform(){
      this.gAdd = false;
      this.$u.api.attach.group.add(this.gform).then(()=>{
        this.gform.title = "";
        this.$message.success("操作成功");
        this.loadGroup();
      })
    },
    moveGroup(itemId,itemConRef=false,gIteConRef=false){
      if(itemConRef)this.$refs[itemConRef][0].doClose();
      if(gIteConRef)this.$refs[gIteConRef][0].doClose();
      this.$u.api.attach.group.move({
        group_id:this.choose_group_id,
        ids:[itemId]
      }).then(()=>{
        this.choose_group_id = 0;
        this.loadList();
      })
    },
    loadGroup(){
      this.$u.api.attach.group.all().then(res=>{
        this.group = res;
      })
    },
    changeCurTab(val){
      this.cur_tab = val;
      this.page = 1;
      this.loadList();
    },
    open(){
      this.loadList();
      this.loadGroup();
    },
    del(item,itemConRef=false){
      if(itemConRef)this.$refs[itemConRef][0].doClose();
      this.$u.api.attach.del({id:item.id}).then(()=>{
        this.loadList();
      })
    },
    pageChange(cur){
      this.page = cur;
      this.loadList();
    },
    loadList(){
      let post = {page:this.page};
      if(this.cur_tab == "all"){
        post.all = true;
      }else {
        post.group_id = this.cur_tab;
      }
      post.type = 1;
      this.$u.api.attach.page(post).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    success(){
      this.$refs.uploader.clearFiles();
      this.loadList();
    },
    error(e){
      console.log(e)
    },
    chooseImg(img){
      this.$emit("change",img);
    }
  }
}
</script>

<style scoped>
  .aside-item.active{
    background-color: #e9eefe;
    color: #3e9afa;
  }
  .aside-item{
    line-height: 3rem;
    padding: 0 2rem;
  }
</style>